import React from 'react'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import SiteLayout from '@layouts/site-layout';

const TagsPage = ({
    data: {
        allMarkdownRemark: { group },
        site: {
            siteMetadata: { title },
        },
    },
}) => (
        <SiteLayout>
            {/* <section className="section">
                <Helmet title={`Tags | ${title}`} />
                <div className="container content">
                    <div className="row">
                        <div
                            className="col-md-12"
                            style={{ marginBottom: '6rem' }}
                        >
                            <h1 className="title is-size-2 is-bold-light">Tags</h1>
                            <ul className="taglist">
                                {group.map(tag => (
                                    <li key={tag.fieldValue}>
                                        <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                                            {tag.fieldValue} ({tag.totalCount})
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </section> */}
        </SiteLayout>
    )

export default TagsPage

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
